<template>
    <div class="nav-bar">
        <div class="nav-left">
            <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" class="collapsed-icon" @click="changeCollapsed"/>
        </div>
        <a-space class="nav-user" @click.stop="showMenu">
            <a-icon type="user" :style="{ fontSize: '16px'}"/>
            <div class="user-name">
                <div class="name-txt">{{user.name}}</div>
                <div class="name-auth">{{user.roleText}}</div>
            </div>
            <a-icon class="name-down" type="down"/>
        </a-space>
        <div class="down-box" v-show="ms">
            <div class="down-box-item" @click.stop="modifyPassword">
                <a-icon type="edit" />
                <div class="down-txt">修改密码</div>
            </div>
            <div class="down-box-item" @click.stop="logout">
                <a-icon type="logout" />
                <div class="down-txt">退出登录</div>
            </div>
        </div>
    </div>
</template>

<script>
    import {cleanToken, getStorageInfo} from "../common/js/storage";
    import {getRoleTypeText} from "../common/constant/org";

    export default {
        name: "nav-bar",
        data() {
            return {
                menu: [],
                ms: false,
                user: null
            }
        },
        computed: {
            login () {
                return this.$store.getters.login;
            },
            collapsed () {
                return this.$store.getters.collapsed;
            }
        },
        watch: {
            login() {
                this.getUser();
            }
        },
        created() {
            this.getUser();
            document.addEventListener('click', this.hideMenu, false);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.hideMenu, false);
        },
        methods: {
            changeCollapsed() {
                this.$store.commit("changeCollapsed", !this.collapsed);
            },
            getUser() {
                let result = null;
                result = getStorageInfo();
                if(result) {
                    result.roleText = getRoleTypeText(result.role);
                }
                this.user = result;
            },
            logout() {
                cleanToken();
                location.replace('/login');
            },
            modifyPassword() {
                this.$router.push({name: 'Password'});
            },
            showMenu() {
                this.ms = !this.ms;
            },
            hideMenu() {
                this.ms = false;
            }
        }
    }
</script>

<style lang="less">
    .nav-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 0 @padding-md;
        height: 64px;
        background-color: @body-background;
        box-shadow: @box-shadow-base;
        z-index: 10;
    }
    .logo {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .collapsed-icon {
        font-size: 20px;
        cursor: pointer;
    }
    .nav-user {
        position: relative;
        padding: 0 8px;
        height: 100%;
        cursor: pointer;
        line-height: 1;
        &:hover {
            background-color: @background-color-light;;
        }
    }
    .name-auth {
        margin-top: 4px;
        color: var(--back-font-color);
        font-size: 12px;
    }
    .user-down {
        margin-left: 4px;
        font-size: 16px;
    }
    .name-down {
        font-size: 12px;
    }
    .down-box {
        position: absolute;
        top: 68px;
        right: 24px;
        min-width: 160px;
        border: var(--border);
    }
    .down-box-item {
        display: flex;
        align-items: center;
        padding: @padding-sm @padding-md;
        cursor: pointer;
        &:hover {
            background-color: @background-color-light;;
        }
    }
    .down-txt {
        margin-left: 8px;
    }
</style>
