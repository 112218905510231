<template>
    <div class="ctx-title">
        <a-breadcrumb class="title-routes" :routes="routes">
            <template v-slot:itemRender="{route}">
                <span v-if="routes.indexOf(route) === routes.length - 1">{{route.breadcrumbName}}</span>
                <router-link v-else :to="route.path">{{route.breadcrumbName}}</router-link>
            </template>
        </a-breadcrumb>
        <div class="title-slot flex-box justify-center">
            <title-slot :vnodes="title"></title-slot>
        </div>
    </div>
</template>

<script>
    import entityConfig from "../common/constant/entity";
    export default {
        name: "title-bar",
        components: {
            'title-slot': {
                functional: true,
                render: (h, ctx) => ctx.props.vnodes
            }
        },
        data() {
            return {
                routes: []
            }
        },
        computed: {
            title() {
                return this.$store.getters.title;
            }
        },
        watch: {
            $route() {
                this.setRoutes();
            }
        },
        created() {
            this.setRoutes();
        },
        methods: {
            setRoutes() {
                let matched = this.$route.matched;
                let params = this.$route.params;
                let type = params.type;
                if(matched.length <= 0) return;
                let routes = [];
                matched.forEach(route => {
                    let name = route.name;
                    let entity = entityConfig[type];
                    switch (name) {
                        case undefined:
                            if(route.path == '/process') {
                                routes.push({
                                    path: '/process',
                                    breadcrumbName: '流程管理'
                                })
                            } else if(route.path == '/entity/:type' && entity) {
                                routes.push({
                                    path: `/entity/${type}`,
                                    breadcrumbName: `${entity.name}管理`
                                })
                            }
                            break;
                        case 'AddEntity':
                            routes.push({
                                path: `/entity/${type}/add`,
                                breadcrumbName: `添加${entity.name}`
                            })
                            break;
                        case 'EntityDetail':
                            routes.push({
                                path: `/entity/${type}/detail/${params.id}`,
                                breadcrumbName: `${entity.name}详情`
                            })
                            break;
                        case 'ProcessAdd':
                            routes.push({
                                path: '/process/add',
                                breadcrumbName: '新增流程'
                            })
                            break;
                        case 'ProcessDetail':
                            routes.push({
                                path: `/process/detail/${params.id}`,
                                breadcrumbName: '流程详情'
                            })
                            break;
                        case 'Approve':
                            routes.push({
                                path: '/approve',
                                breadcrumbName: '我的审批'
                            })
                            break;
                        case 'Organization':
                            routes.push({
                                path: '/org',
                                breadcrumbName: '人员管理'
                            })
                            break;
                    }
                });
                this.routes = routes;
            }
        }
    }
</script>

<style scoped lang="less">
    .ctx-title {
        flex-shrink: 0;
        position: relative;
        padding: @padding-md;
        min-height: 54px;
        background-color: @body-background;
        border-bottom: var(--border);
        font-size: @font-size-lg;
    }
    .title-routes {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto 0;
        padding: 0 @padding-md;
        height: 20px;
        line-height: 20px;
    }
</style>
