import {getStorageInfo} from "../js/storage";

const roleMenus = {
    role_1: [
        {
            title: '我的审批',
            path: '/approve',
            icon: 'form'
        },
        {
            title: '群团管理',
            path: '/entity/group',
            icon: 'idcard'
        },
        {
            title: '场地管理',
            path: '/entity/site',
            icon: 'home'
        },
        {
            title: '活动管理',
            path: '/entity/activity',
            icon: 'star'
        },
        {
            title: '政情知晓',
            path: '/entity/policy',
            icon: 'profile'
        },
        {
            title: '同心学堂',
            icon: 'cloud',
            path: '/entity',
            children: [
                {
                    title: '直播管理',
                    path: '/entity/live',
                },
                {
                    title: '课程管理',
                    path: '/entity/course',
                },
                {
                    title: '讲师管理',
                    path: '/entity/teacher',
                },
            ]
        },
        {
            title: '数据上传',
            icon: 'line-chart',
            path: '/data',
            children: [
                {
                    title: '人物事迹',
                    path: '/entity/story',
                },
                {
                    title: '参观记录',
                    path: '/entity/visit',
                },
            ]
        },
        {
            title: '横幅管理',
            path: '/entity/banner',
            icon: 'picture'
        },
        {
            title: '公告管理',
            path: '/entity/notice',
            icon: 'bulb'
        },
        {
            title: '人员管理',
            path: '/org',
            icon: 'team'
        },
        {
            title: '流程管理',
            path: '/process',
            icon: 'apartment'
        },
        {
            title: '系统日志',
            path: '/log',
            icon: 'project'
        },
        {
            title: '数据大屏',
            path: '/screen',
            icon: 'area-chart',
            blank: true
        },
    ],
    role_2: [
        {
            title: '我的审批',
            path: '/approve',
            icon: 'form'
        }
    ]
}

export const getMenus = function () {
    let role = 2;
    let user = getStorageInfo();
    if(user) {
        role = user.role || 2;
    }
    return roleMenus[`role_${role}`]
}
